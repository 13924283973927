<template>
  <div class="m-pg-sub">
    <page-cover code="7" layer="rgba(0,0,0,.3)">
      <!-- <h2 class="m-page-title">{{$t('page.reserve.name')}}</h2>
      <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('page.reserve.name')}}</el-breadcrumb-item>
      </el-breadcrumb> -->
    </page-cover>
    <div class="m-pg-sub_main m-w1200">
      <el-form class="filter" :model="filter" :inline="true">
        <el-form-item label="仪器名称">
          <el-input v-model="filter.Name"></el-input>
        </el-form-item>
        <el-form-item label="实验室">
          <el-select v-model="filter.Typelab" clearable>
            <el-option
              v-for="item in dict.list_lab_Typelab"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类标签">
          <el-select v-model="filter.Tags" clearable>
            <el-option
              v-for="item in dict.list_lab_Tagst"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="getData">查询</el-button>
      </el-form>
      <!-- <el-steps class="step" :active="1">
        <el-step title="步骤 1" description="使用说明"></el-step>
        <el-step title="步骤 2" description="使用说明"></el-step>
        <el-step title="步骤 3" description="使用说明"></el-step>
      </el-steps> -->

      <el-table
        class="el-table--custom"
        v-loading="table.loading"
        :data="table.data"
        row-key="Lab_apparatus_ID"
        size="medium"
        stripe>
        <el-table-column
          prop="Name"
          label="仪器名称"
          width="400">
        </el-table-column>

        <el-table-column
          prop="name"
          label="实验室归属"
          width="360">
          <template slot-scope="{ row, column, $index }">
            {{row.Typelab | dictMap(dict.list_lab_Typelab)}}
          </template>
        </el-table-column>

        <el-table-column
          prop="Tags"
          label="分类标签"
          show-overflow-tooltip
          width="400">
          <template slot-scope="{ row, column, $index }">
            <template v-if="row.Tags">
              <el-tag
                v-for="item in row.Tags.split(',')"
                :key="item"
                type="info"
                effect="dark"
                style="margin-right: 4px;">
                {{ item | dictMap(dict.list_lab_Tagst)}}
              </el-tag>
            </template>
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          prop="name"
          width="180"
          label="操作">
          <template slot-scope="{ row, column, $index }">
            <!-- <el-button :title="$t('action.detail')" size="mini" type="primary" icon="zm-icon zm-icon-antou" @click="onDetail(row)"> 详情</el-button> -->
            <el-button :title="$t('page.result.action.reserve')" size="mini" type="primary" icon="zm-icon zm-icon-jianyi" @click="onReserve(row)"> 预约</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pager"
        background
        layout="prev, pager, next"
        :page-size.sync="page.size"
        :current-page.sync="page.index"
        :total="page.total"
        @current-change="onPageChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
import util from '@/utils'
import commonApi from '@/api/common'
import * as labApparatusApi from '@/api/labApparatus'
export default {
  components: {
  },
  data() {
    return {
      loading: false,
      filter: {
        Name: '',
        Typelab: '', // 实验室
        Tags: '' // 分类标签
      },
      dict: {
        list_lab_Typelab: [], // 实验室
        list_lab_TypeTest: [], // 测试项目
        list_lab_Tagst: [], // 分类标签
        status: [] // 状态
      },
      table: {
        loading: false,
        data: []
      },
      page: {
        index: 1,
        size: 10,
        total: 0
      }
    }
  },
  inject: ['validateLogin'],
  methods: {
    async getDict() {
      const dicts = await commonApi.getVueDictionary([
        "list_lab_Typelab",
        "list_lab_TypeTest",
        "list_lab_Tagst",
        "status"
      ])
      if (dicts && dicts.length) {
        this.dict.list_lab_Typelab = dicts.find(item => item.dicNo === 'list_lab_Typelab').data
        this.dict.list_lab_TypeTest = dicts.find(item => item.dicNo === 'list_lab_TypeTest').data
        this.dict.list_lab_Tagst = dicts.find(item => item.dicNo === 'list_lab_Tagst').data
        this.dict.status = dicts.find(item => item.dicNo === 'status').data
      }
    },
    // 组装where条件
    assembleWheres() {
      let wheres = []
      let filter = this.filter
      if (filter.Name) {
        wheres.push({name: 'Name', value: filter.Name, displayType: 'text'})
      }
      if (filter.Typelab) {
        wheres.push({name: 'Typelab', value: filter.Typelab, displayType: 'select'})
      }
      if (filter.Tags) {
        wheres.push({name: 'Tags', value: filter.Tags, displayType: 'select'})
      }
      return wheres
    },
    getData() {
      this.table.loading = true
      let wheres = this.assembleWheres()
      labApparatusApi.getPageData({
        wheres: JSON.stringify(wheres),
        page: this.page.index,
        rows: this.page.size
      }).then(res => {
        this.table.loading = false
        this.table.data = res.rows
        this.page.total = res.total
      }).catch(err => {
        this.table.loading = false
      })
    },
    onDetail(row) {
      this.$router.push(`/reserve/detail`)
    },
    onReserve(row) {
      // this.validateLogin()
      this.$router.push(`/reserve/apply?labApparatusId=${row.Lab_apparatus_ID}`)
    },
    onPageChange(val) {
      this.getData()
    }
  },
  mounted() {
    this.getDict()
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    margin: 20px auto;
    padding: 0 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .filter {
      padding: 20px 0;
      /deep/ .el-form-item {
        margin-bottom: 0;
      }
      /deep/ .el-input {
        width: 193px;
      }
    }
    .step {
      margin-top: 30px;
    }
    .tb-data {
      // margin-top: 30px;
    }
  }
</style>
